.home-page-container {
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative; }
  @media screen and (max-width: 900px) {
    .home-page-container h1,
    .home-page-container h2,
    .home-page-container h3,
    .home-page-container h4 {
      color: white !important; } }
  .home-page-container .home-page-bg {
    filter: grayscale(100%);
    height: 100%;
    width: 100%;
    background: url("../photos/lambo.png");
    position: absolute;
    z-index: -1; }
    .home-page-container .home-page-bg:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 900px) {
      .home-page-container .home-page-bg {
        background-position-x: 20%;
        background-position-y: -15%; } }
    @media screen and (max-width: 586px) {
      .home-page-container .home-page-bg {
        background-position-y: -7%; } }
  .home-page-container #home-text-main {
    color: white;
    margin: 1rem;
    font-size: 8rem; }
    @media screen and (max-width: 1840px) {
      .home-page-container #home-text-main {
        font-size: 7rem; } }
    @media screen and (max-width: 1200px) {
      .home-page-container #home-text-main {
        font-size: 6rem; } }
    @media screen and (max-height: 767px) {
      .home-page-container #home-text-main {
        font-size: 6rem; } }
    @media screen and (max-width: 900px) {
      .home-page-container #home-text-main {
        font-size: 2rem;
        margin: 0.5rem;
        color: lightblue !important; } }
  .home-page-container .home-text-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    justify-content: center; }
    @media screen and (max-width: 900px) {
      .home-page-container .home-text-container {
        top: 15%;
        flex-direction: column; } }
    @media screen and (max-width: 900px) {
      .home-page-container .home-text-container .main-text-container {
        display: flex;
        flex-direction: row; } }
    .home-page-container .home-text-container .white-box {
      background-color: white;
      height: 95vh;
      width: 500px;
      margin-left: 8rem;
      display: flex;
      flex-direction: column; }
      @media screen and (max-width: 1200px) {
        .home-page-container .home-text-container .white-box {
          width: 350px; }
          .home-page-container .home-text-container .white-box > * {
            padding-top: 3rem; } }
      @media screen and (max-width: 900px) {
        .home-page-container .home-text-container .white-box {
          all: unset;
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: 0; } }
      .home-page-container .home-text-container .white-box .button-container {
        display: flex;
        align-items: center;
        justify-content: space-evenly; }
      .home-page-container .home-text-container .white-box #verify-text {
        color: #424242;
        margin-top: 50%;
        font-size: 3rem; }
        @media screen and (max-width: 1200px) {
          .home-page-container .home-text-container .white-box #verify-text {
            font-size: 2rem; } }
        @media screen and (max-height: 900px) {
          .home-page-container .home-text-container .white-box #verify-text {
            margin-top: 25%; } }
        @media screen and (max-width: 900px) {
          .home-page-container .home-text-container .white-box #verify-text {
            display: none;
            font-size: 2rem;
            margin: 1rem 0 1rem 0;
            color: white; } }
      .home-page-container .home-text-container .white-box #years-text {
        font-weight: 2;
        font-size: 3rem;
        margin: 1rem;
        color: #424242; }
        @media screen and (max-width: 586px) {
          .home-page-container .home-text-container .white-box #years-text {
            font-size: 1.4rem !important; } }
        @media screen and (max-width: 1200px) {
          .home-page-container .home-text-container .white-box #years-text {
            font-size: 2rem; } }
        @media screen and (max-width: 900px) {
          .home-page-container .home-text-container .white-box #years-text {
            font-size: 1.5rem;
            padding-top: 0px;
            color: white; } }
        @media screen and (max-height: 767px) {
          .home-page-container .home-text-container .white-box #years-text {
            font-size: 2rem; } }
      .home-page-container .home-text-container .white-box #i-can-text {
        margin: 1rem;
        font-size: 2rem;
        color: #424242;
        font-weight: lighter; }
        @media screen and (max-width: 1200px) {
          .home-page-container .home-text-container .white-box #i-can-text {
            font-size: 1.25rem; } }
        @media screen and (max-width: 900px) {
          .home-page-container .home-text-container .white-box #i-can-text {
            padding: 1rem 0 0 0;
            font-size: 1rem;
            margin: 1rem;
            color: white; } }
        @media screen and (max-height: 767px) {
          .home-page-container .home-text-container .white-box #i-can-text {
            font-size: 1.5rem; } }
      .home-page-container .home-text-container .white-box .underline {
        padding: 0; }
      .home-page-container .home-text-container .white-box .underline:after {
        margin-top: 2rem;
        content: "";
        display: block;
        width: 20%;
        height: 4px;
        background-color: red; }
    .home-page-container .home-text-container span {
      margin: 1rem; }
      @media screen and (max-width: 900px) {
        .home-page-container .home-text-container span {
          margin: 0;
          padding: 0; } }

.contact-page-container {
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative; }
  .contact-page-container .contact-page-bg {
    filter: grayscale(100%);
    height: 100%;
    width: 100%;
    background: url("../photos/car.jpeg") no-repeat center center fixed;
    position: absolute;
    background-size: cover;
    z-index: -1; }
    .contact-page-container .contact-page-bg:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 900px) {
      .contact-page-container .contact-page-bg {
        background-position-x: 50%;
        background-position-y: 75% !important; } }
  .contact-page-container .contact-page-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    margin-top: 10%;
    font-weight: 300;
    font-family: "Lora", serif; }
    .contact-page-container .contact-page-text h1,
    .contact-page-container .contact-page-text h2,
    .contact-page-container .contact-page-text h3,
    .contact-page-container .contact-page-text h4 {
      color: white; }
    .contact-page-container .contact-page-text h1 {
      color: lightblue !important; }
    .contact-page-container .contact-page-text #by-apt-text {
      font-weight: bold;
      margin: 1rem; }
    .contact-page-container .contact-page-text .contact-row {
      margin: 2rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center; }
      @media screen and (max-width: 720px) {
        .contact-page-container .contact-page-text .contact-row {
          flex-direction: column; } }
      .contact-page-container .contact-page-text .contact-row h2 {
        margin: 0 2rem 0 0;
        font-weight: bold;
        padding: 0 1rem; }
      .contact-page-container .contact-page-text .contact-row i {
        margin-left: 2rem;
        transform: scaleX(-1); }
  @media screen and (max-width: 586px) {
    .contact-page-container #schedule-text {
      margin-top: 10%;
      font-size: 1.3rem; } }
  @media screen and (max-width: 586px) {
    .contact-page-container #list-text {
      margin: 1rem;
      font-size: 0.9rem; } }
  .contact-page-container .contact-set {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 586px) {
      .contact-page-container .contact-set #contact-email,
      .contact-page-container .contact-set #contact-phone {
        font-size: 1.25rem; } }
    @media screen and (max-width: 720px) {
      .contact-page-container .contact-set {
        margin: 1rem; } }
  .contact-page-container h1,
  .contact-page-container h2,
  .contact-page-container h3,
  .contact-page-container h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase; }
  .contact-page-container #home-text-main {
    color: white;
    margin: 1rem;
    font-size: 8rem; }
    @media screen and (max-width: 1840px) {
      .contact-page-container #home-text-main {
        font-size: 7rem; } }
    @media screen and (max-width: 1200px) {
      .contact-page-container #home-text-main {
        font-size: 6rem; } }
    @media screen and (max-width: 900px) {
      .contact-page-container #home-text-main {
        font-size: 2rem;
        margin: 0.5rem; } }
  .contact-page-container .home-text-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    justify-content: center; }
    @media screen and (max-width: 900px) {
      .contact-page-container .home-text-container {
        top: 15%;
        flex-direction: column; } }
    @media screen and (max-width: 900px) {
      .contact-page-container .home-text-container .main-text-container {
        display: flex;
        flex-direction: row; } }

.pricing-page-container {
  height: 100vh;
  width: 100%;
  position: relative; }
  .pricing-page-container h1,
  .pricing-page-container h2,
  .pricing-page-container h3,
  .pricing-page-container h4 {
    font-weight: bold;
    text-transform: uppercase; }
  .pricing-page-container h2 {
    color: white; }
  .pricing-page-container h1 {
    font-size: 3rem;
    color: lightblue;
    font-weight: bold; }
  .pricing-page-container #payment {
    color: white !important; }
  .pricing-page-container .pricing-page-bg {
    filter: grayscale(100%);
    height: 100%;
    width: 100%;
    background: url("../photos/cristofer-jeschke--fw7ypjhifk-unsplash__5710x3807___v1220x813.jpeg");
    position: absolute;
    background-size: cover;
    z-index: -1; }
    .pricing-page-container .pricing-page-bg:after {
      content: " ";
      z-index: 10;
      display: block;
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5); }
  .pricing-page-container .pricing-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex; }
    .pricing-page-container .pricing-content #main-text {
      margin-top: 10%; }
      @media screen and (max-width: 586px) {
        .pricing-page-container .pricing-content #main-text {
          margin-top: 25%;
          font-size: 2rem; } }
    @media screen and (max-width: 586px) {
      .pricing-page-container .pricing-content h2 {
        font-size: 1.4rem; }
      .pricing-page-container .pricing-content #payment {
        margin-top: 3rem; } }
    .pricing-page-container .pricing-content .projects {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 7rem; }
      .pricing-page-container .pricing-content .projects .projects-container {
        width: 60vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 10%; }
        .pricing-page-container .pricing-content .projects .projects-container .projects-project {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          overflow: hidden;
          margin: 0 2rem 2rem 2rem; }
          .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons {
            display: flex;
            flex-direction: row; }
            .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: white;
              padding: 0.75rem; }
              .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container h1 {
                text-transform: initial !important;
                margin: 0;
                color: WHITE !important;
                font-weight: 900 !important; }
              .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container img {
                filter: brightness(9) contrast(6) hue-rotate(10deg) saturate(4.9); }
              .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container #zelle {
                margin-right: -1rem;
                width: 100px; }
                @media screen and (max-width: 586px) {
                  .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container #zelle {
                    width: 85px; } }
              .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container #venmo {
                margin-top: 0.2rem;
                width: 100px; }
                @media screen and (max-width: 586px) {
                  .pricing-page-container .pricing-content .projects .projects-container .projects-project .payment-icons .payment-container #venmo {
                    width: 85px; } }
          .pricing-page-container .pricing-content .projects .projects-container .projects-project hr {
            width: 90%; }
          .pricing-page-container .pricing-content .projects .projects-container .projects-project h1 {
            font-size: 2rem;
            margin-bottom: 0px;
            color: white; }

.about-page-container {
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 100%;
  position: relative; }
  .about-page-container .about-page-bg {
    filter: grayscale(100%);
    height: 100%;
    width: 100%;
    background: url("../photos/IMG_y32jd1.jpg") no-repeat center center fixed;
    position: absolute;
    background-size: cover;
    z-index: -1; }
    @media screen and (max-width: 900px) {
      .about-page-container .about-page-bg {
        background-position-x: 20%;
        background-position-y: 75%; } }
  .about-page-container .contact-page-text {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    flex-direction: column;
    margin-top: 10%;
    font-weight: 300;
    font-family: "Lora", serif; }
    .about-page-container .contact-page-text h1,
    .about-page-container .contact-page-text h2,
    .about-page-container .contact-page-text h3,
    .about-page-container .contact-page-text h4 {
      color: #00032c !important; }
    .about-page-container .contact-page-text #by-apt-text {
      margin: 1rem; }
    .about-page-container .contact-page-text .contact-row {
      margin: 2rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center; }
      @media screen and (max-width: 720px) {
        .about-page-container .contact-page-text .contact-row {
          flex-direction: column; } }
      .about-page-container .contact-page-text .contact-row h2 {
        margin: 0 2rem 0 0;
        color: #00032c;
        font-weight: bold;
        padding: 0 1rem; }
      .about-page-container .contact-page-text .contact-row i {
        margin-left: 2rem;
        transform: scaleX(-1); }
  @media screen and (max-width: 586px) {
    .about-page-container #schedule-text {
      font-size: 1.5rem; } }
  @media screen and (max-width: 586px) {
    .about-page-container #list-text {
      font-size: 0.9rem; } }
  .about-page-container .contact-set {
    display: flex;
    flex-direction: row; }
    @media screen and (max-width: 720px) {
      .about-page-container .contact-set {
        margin: 1rem; } }
  .about-page-container h1,
  .about-page-container h2,
  .about-page-container h3,
  .about-page-container h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    text-transform: uppercase; }
  .about-page-container #home-text-main {
    color: white;
    margin: 1rem;
    font-size: 8rem; }
    @media screen and (max-width: 1840px) {
      .about-page-container #home-text-main {
        font-size: 7rem; } }
    @media screen and (max-width: 1200px) {
      .about-page-container #home-text-main {
        font-size: 6rem; } }
    @media screen and (max-width: 900px) {
      .about-page-container #home-text-main {
        font-size: 2rem;
        margin: 0.5rem; } }
  .about-page-container .home-text-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    justify-content: center; }
    @media screen and (max-width: 900px) {
      .about-page-container .home-text-container {
        top: 15%;
        flex-direction: column; } }
    @media screen and (max-width: 900px) {
      .about-page-container .home-text-container .main-text-container {
        display: flex;
        flex-direction: row; } }

.hamburger-menu {
  position: absolute;
  height: 100vh;
  width: 100vw;
  overflow: hidden; }

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  position: fixed;
  font-family: "Lora", serif;
  animation: fadeInAnimation 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards; }
  body a,
  html a {
    transition: all 1s ease;
    text-decoration: none;
    color: white; }
    body a:hover,
    html a:hover {
      color: red; }

h1,
h2,
h3,
h4,
a {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300; }

.hamburger-react {
  height: 48px !important;
  z-index: 5;
  color: white;
  position: absolute !important; }

@keyframes fadeInAnimation {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
